import React from 'react';

import Typography from '@material-ui/core/Typography';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Gallery from '../components/Gallery';

const Page = () => (
  <Layout noheader>
    <SEO title="Barrachin en images" />

    <Typography variant="h2" component="h1" gutterBottom>
      Barrachin en images
    </Typography>

    <Gallery page="barrachin" />
  </Layout>
);

export default Page;
